// .center {
//     margin: auto;
//     width: 50%;
//     text-align: center;
// }

// .detailViewTitle {
//     margin-left: 1.75em;
//     font-weight: 700;
//     margin-top: 0;
// }

// .detailViewCon {
//     display: flex;
//     justify-content: space-around;
//     max-height: 77vh;

//     .k-animation-container {
//         width: 100%;
//     }

    .fileViewer {
        // padding-top: 1em;
        width: 1000px;
        max-width: 1000px;
        min-width: 1000px;
        margin-right: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: 80%;

        a {
            color: #0068ad !important;
            text-decoration: underline !important;
        }
    }

//     .detailViewer {
//         width: 36%;
//         margin-right: 4em;
//         margin-top: 0;

//         .k-link:hover {
//             color: #494440 !important;
//             background-color: transparent !important;
//         }

//         .k-tabstrip-items {
//             display: flex;
//             justify-content: center;
//         }

//     }


// }

// .contentRight {
//     display: flex;
//     flex-direction: column;
//     width: 36%;
// }


// .cardStyle {
//     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
//     border: solid 2px rgb(107, 106, 106);
//     border-radius: 5px;
// }

// .changePage {
//     display: flex;
//     margin-top: 1.5em;
// }

// .document {
//     padding-bottom: 1em;
//     max-width: 100%;
//     min-width: 100%;
//     overflow-y: auto;
//     overflow-x: auto;
// }

// .k-tabstrip-top>.k-tabstrip-items .k-item.k-state-active {
//     box-shadow: 0 -0.4rem 0 0 #205493 inset;
// }

// .k-link {
//     font-size: 1.7rem;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

// .detailsPreviewTab {
//     .k-tabstrip-items-wrapper .k-item.k-state-active,
//     .k-tabstrip-items-wrapper .k-item.k-state-selected {
//         width: 100%;
//     }
//     .k-tabstrip-items .k-link {
//         font-size: 2rem;
//         color: #222 !important;
//         font-weight: 800;
//       }
// }


// .k-tabstrip-items .k-item {
//     color: #494440 !important;
//     border: none !important;
// }

// .k-tabstrip-top>.k-tabstrip-items .k-item:hover {
//     box-shadow: 0 -0.4rem 0 0 #75716e inset;
// }

// .k-content {
//     border: none !important;
// }

// .detailContent {
//     margin-top: 2em;
// }

// .metaItem {
//     .metaName {
//         margin-right: 8px;
//         font-weight: bold;
//     }

// }

// .detailSection {
//     margin-bottom: 24px;
// }

// .detailSectionTitle {
//     display: flex;
//     justify-content: space-between;
//     align-items: baseline;
//     width: 100%;
//     border-bottom: 1px solid rgba(0, 0, 0, 0.08);
//     margin-bottom: 12px;

//     button {
//         margin-right: 12px;
//         padding-top: 0;
//         padding-left: 2rem;
//         padding-right: 2rem;
//         padding-bottom: 0;
//         height: 32px;
//         background-color: #fff;
//         color: #205493;
//         border: 0.75px solid #205493;
//     }
// }



// .detailSectionContent {
//     .metaItem {
//         margin-bottom: 12px;
//     }
// }

// .float-right {
//     float: right;
// }

// .producer-item {
//     overflow-y: auto;
//     max-height: 20em;
//     max-width: 100%;
// }

// .vote-detail-col {
//     border-radius: 5px;
//     border: groove;
// }

// .vote-detail {
//     font-size: 14px;
//     margin-top: 5px;
//     margin-bottom: 5px;
//     margin-left: 1em;
//     margin-right: 0em;

// }

// .vote-detail-row {
//     width: 100%;
// }

// .k-listview {
//     width: 100%;
// }

// .metadata {
//     max-height: 40em;
//     overflow-y: auto;
//     margin-bottom: 1em;
// }

// .cardStyle {
//     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
//     border: solid 2px rgb(107, 106, 106);
//     border-radius: 5px;
// }

// .returnedData {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     // border-bottom: solid 1px black;
//     padding: 1em;
// }

// .returnedData:hover {
//     background-color: rgba(0, 0, 0, 0.2);
// }

// .keyfont {
//     font-weight: bold;
//     margin-right: 1em;
// }

// .valuefont {
//     text-align: right;
// }

// .image-wrapper {
//     margin-bottom: 1em;
//     overflow: hidden;
//     max-height: 700px;
//     max-width: 1025px;
//     overflow-y: auto;
//     overflow-x: auto;

// }

// img.hover-zoom {
//     min-height: 1025px;
//     min-width: 650px;

// }

// img.hover-zoom:hover {
//     transform: scale(1.5);
//     min-height: 1025px;
//     min-width: 650px;
// }

// // Dispostion information
// .disposition-information-text {
//     margin-left: 20px;
//     color: red;
//     font-size: 12px;
// }

// .disposition-information-section {
//     border: 3px solid red;
//     height: auto;
//     padding: 5px 0px 5px 10px;

//     .disposition-information-content {
//         margin: 3px 20px;
//     }

//     .cutoff-date {
//         margin-bottom: 10px;
//     }

//     .cutoff-date-text {
//         color: red;
//         margin-right: 10px;
//     }

//     .readonly-datepicker {
//         margin-left: 10px;
//     }

//     .disposition-datepicker {
//         margin-left: 30px;
//     }


//     // .Disposition-disabled-date{
//     //     pointer-events: none;
//     //      opacity: 0.6
//     // }
// }

// .tableRowSearchDetails {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     margin: 5px;

//     .tableRowObjectType {
//         width: 70%;
//         color: rgba(73, 68, 63, 0.76);
//         font-size: 1em;
//         border-radius: 10px;
//         background: rgba(235, 235, 235, 0.42);
//         padding: 1rem 1.8rem 1rem 1rem;
//         cursor: pointer;

//         .optionsImage {
//             visibility: hidden;
//         }
//     }

//     .tableRowDate {
//         width: 119px;
//         color: rgba(73, 68, 63, 0.76);
//         font-size: 1em;
//         border-radius: 10px;
//         background: rgba(235, 235, 235, 0.42);
//         padding: 1rem 1.8rem 1rem 1rem;
//         cursor: pointer;
//     }

//     .clickedData {
//         color: #fff;
//         background-color: #25a4db;

//     }
// }
.detailViewerPreview .k-link{
    width: 100% !important;
}

.glbPreviewDocModal {
    width: 100% !important;
}