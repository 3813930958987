.job {
  margin: 0;
  margin-bottom: 5px;
  overflow-inline: hidden;
}

.job-header-top {
  padding: 0;
  border-left: 'solid';
  border-width: '1em';
  font-size: 15.5px;
}

.job-header {
  flex: 0 0 100%;
  max-height: 80%;
  min-height: 40%;
  max-width: 100%;
  padding-left: 10px;
  padding-top: 2px;
  padding-bottom: 1.5em;
  background-color: #f0f0f0;
  border-bottom: solid;
  border-color: #dbdad9;
  border-width: 2px;
  overflow: hidden;
}
.documentsListview {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 14;
}

// candiate information div
.docListViewEdition {
  display: inline-block;
  white-space: nowrap;
  width: 15rem;

  text-overflow: ellipsis;
  margin: 0px;
  overflow: hidden;
}

// user div
.docListViewUser {
  display: inline-block;
  width: 26rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// preview span
.docListViewPreview {
  margin-right: 5px;

  cursor: pointer;
  color: #00ff;
  text-decoration: underline;
}
// page div
.docListViewPage {
  font-size: 13;
  float: left;
  min-width: 10%;
  margin-right: 25%;
}
// description div
.docListViewDescription {
  font-size: 13;
  float: left;
  max-width: 50%;
  word-break: break-word;
}
.job-description {
  flex: 0 0 100%;
  padding-left: 10px;
  margin-top: 10px;
  max-height: 60%;
  min-height: 60%;
  max-width: 100%;
  overflow: hidden;
}
.job-status {
  font-size: 13;
  float: left;
  min-width: 10%;
}

.description {
  font-size: 13;
  float: left;
  max-width: 60%;
  word-break: break-word;
  position: sticky;
  left: 35%;
}

.file-name {
  min-width: 10%;
  max-width: 50%;
}

.user-name {
  left: 62%;
  position: sticky;
}

.upload {
  left: 35%;
  position: sticky;
}

.fs-14 {
  font-size: 14px;
}
