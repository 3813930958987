.fsa-field {
  & {
    position: relative;
    margin-bottom: 1em;

    h1 + & {
      margin-top: 1em;
    }

    @include breakpoint(M) {
      max-width: $fsa-form-width;
    }
  }

  // Pseudos ------------------------------------------------------------------

  &:before {
    @include breakpoint(M) {
      content: '';
      position: absolute;
      top: 4px;
      left: -1em;
      bottom: 6px;
      width: 4px;
      background-color: transparent;
      transition: all 0.1s ease-in-out;
    }
  }

  // Children ------------------------------------------------------------------

  &__label {
    display: block;

    .fsa-field--error & {
      font-weight: $font-bold;
    }
  }

  &__label-desc {
    font-style: italic;
    font-weight: $font-normal;
    float: right;
    color: $color-fsa-tertiary-300;
    font-size: $form-text-size--sm;
    margin-top: 3px; // magic number
  }

  &__help {
    margin-top: $size-base;
    display: block;
    color: $color-fsa-tertiary-300;
    font-size: $form-text-size--sm;
  }

  &__message {
    font-weight: $font-bold;
    font-size: $font-size-2;
    padding-top: $size-small;

    display: block;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: all 0.8s ease-in;
    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }

    &:empty {
      max-height: 0;
    }

    // scss-lint:disable SelectorFormat
    .fsa-field--error & {
      max-height: 120px;
      opacity: 1;
    }
  }

  &__column {
    @include breakpoint(M, down) {
      margin-top: 1em;
    }
  }

  &__item {
    // display: block; commented to properly align dropdown indicator icon and calender icon
    width: 100%;

    &--short {
      max-width: 6rem;
    }

    &--medium {
      @include breakpoint(M) {
        max-width: ($fsa-form-width / 2);
      }
    }

    &[size] {
      width: auto;
    }
  }

  // Variations ----------------------------------------------------------------

  &--block {
    @include breakpoint(M) {
      max-width: none;
    }
  }

  &--error {
    &:before {
      @include breakpoint(M) {
        top: -4px;
        bottom: -6px;
        background-color: $color-fsa-red;
      }
    }
  }

  &--columns {
    @include breakpoint(M, down) {
      margin-top: -1em;
    }
  }
}
