.ruleDefinitionModal {
    .modalHeader {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .modalHeaderTitle {
            margin: 0;
            font-size: 24px;
            font-weight: 550;
            color: #484440;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.44px;
            font-family: sans-serif;
        }

        .modalHeaderIcon {
            cursor: pointer;
        }
    }
    .ruleDefinitionFooter {
        padding: 0;

        .footerInternalDiv {
            width: 100%;
            height: 53px;
            display: flex;
            align-items: center;
            justify-content: center;  
        }

        .saveRecordBtn {
            background: #28A745 !important;
            color: white !important;
            border-color: transparent !important;
        }
    
        .cancelRecordBtn {
            background: white;
            color: #1F5492;
            border: 1px solid #1F5492;
        }
    }
}

.saveRecordBtn {
  background: #28A745 !important;
  color: white !important;
  border-color: transparent !important;
}

.ruleDefinitionTable {
    max-width: calc(100vw - 12rem);
}

.requiredFieldError {
    color: #EB5757;
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
}

.BUOWDisplayNameModalBody {
    padding: 1.5rem;

    .modalBodySection {
      color: var(--text-01-body, #212529);
      font-family: sans-serif;
      // font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.24px;
      padding: 0px 5px;
    }

    .inputFieldSection {
      // height: 80px;
      width: 570px;
      display: flex;
      flex-wrap: wrap;
      font-size: 15px;
      margin: 1.5rem 0rem;
      gap: 2rem;

      .inputFieldInternalDiv {
        display: flex;
        flex-direction: column;
        width: 48%;
        align-items: flex-start;
      }
      .radioBtnContainer {
        display: flex;
        align-items: center;
      }

      label {
        display: flex;
        margin-bottom: 0.2rem !important;
      }

      input {
        display: block;
        width: 100%;
        height: 32px;
        border: 1px solid #ced4da;
        border-radius: 4px;
      }

      select {
        display: block;
        width: 100%;
        height: 32px;
        background-color: #fff;
        font-weight: 400;
        border: 1px solid #ced4da;
        border-radius: 4px;
      }

      .radioBtnContainer {
        display: flex;
        align-items: center;
      }

      .dropdownStyle {
        border-radius: 5px;
        background-color: #fff;
        padding: 0 3px !important;
        border: 1px solid #ced4da;
        height: 32px;
      }

      .radioInputOuterDiv {
        display: flex;
        // margin-top: 3rem;
        align-items: center;

        .radioBtnText {
          // padding-left: 0px;
          // padding-right: auto;
        }

        input {
          width: 20px;
          height: 20px;
          margin: 0px 8px;
          border-radius: 10px;
          display: inline-grid;
        }
      }

      .radioInputOuterDiv input[type='radio'] {
        appearance: none;
        -webkit-appearance: none;
        width: 18px;
        height: 18px;
        margin: 0px 8px;
        border-radius: 10px;
        display: inline-grid;
        border: 3px solid #ced4da;
        background-color: white;
      }

      .radioInputOuterDiv input[type='radio']:checked {
        background-color: #1f5492;
      }
    }

    .searchFieldDiv {
      flex: 1 0 20%;
      height: 80px;
    }

    // Uploade image section
    .uploadImageSection {
      display: flex;
      flex-direction: column;
      width: 100%;

      .imageContents {
        input {
          min-width: 10rem;
          border: none;
          height: auto;
          padding: 0;
        }

        .iconInput {
          display: flex;
        }

        .imageStyle {
          width: 8rem;
          height: 8rem;
          border-radius: 50%;
          background-size: cover;
          background: white;
          object-fit: contain;
          border: 3px solid grey;
          margin-top: -3rem;
        }
      }
    }
  }
  .BUOWDisplayNameModal {
    .modalHeader {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
  
      .modalHeaderTitle {
        margin: 0;
        font-size: 24px;
        font-weight: 550;
        color: #484440;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.44px;
        font-family: sans-serif;
      }
  
      .modalHeaderIcon {
        cursor: pointer;
      }
    }
  
    .BUOWDisplayNameModalBody {
      padding: 1.5rem;
  
      .modalBodySection {
        color: var(--text-01-body, #212529);
        font-family: sans-serif;
        // font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.24px;
        padding: 0px 5px;
      }
  
      .inputFieldSection {
        // height: 80px;
        width: 570px;
        display: flex;
        flex-wrap: wrap;
        font-size: 15px;
        margin: 1.5rem 0rem;
        gap: 2rem;
  
        .inputFieldInternalDiv {
          display: flex;
          flex-direction: column;
          width: 48%;
          align-items: flex-start;
        }
        .radioBtnContainer {
          display: flex;
          align-items: center;
        }
  
        label {
          display: flex;
          margin-bottom: 0.2rem !important;
        }
  
        input {
          display: block;
          width: 100%;
          height: 32px;
          border: 1px solid #ced4da;
          border-radius: 4px;
        }
  
        select {
          display: block;
          width: 100%;
          height: 32px;
          background-color: #fff;
          font-weight: 400;
          border: 1px solid #ced4da;
          border-radius: 4px;
        }
  
        .radioBtnContainer {
          display: flex;
          align-items: center;
        }
  
        .dropdownStyle {
          border-radius: 5px;
          background-color: #fff;
          padding: 0 3px !important;
          border: 1px solid #ced4da;
          height: 32px;
        }
  
        .radioInputOuterDiv {
          display: flex;
          margin-top: 2.5rem;
          align-items: center;
  
          .radioBtnText {
            // padding-left: 0px;
            // padding-right: auto;
          }
  
          input {
            width: 20px;
            height: 20px;
            margin: 0px 8px;
            border-radius: 10px;
            display: inline-grid;
          }
        }
  
        .radioInputOuterDiv input[type='radio'] {
          appearance: none;
          -webkit-appearance: none;
          width: 18px;
          height: 18px;
          margin: 0px 8px;
          border-radius: 10px;
          display: inline-grid;
          border: 3px solid #ced4da;
          background-color: white;
        }
  
        .radioInputOuterDiv input[type='radio']:checked {
          background-color: #1f5492;
        }
      }
  
      .searchFieldDiv {
        flex: 1 0 20%;
        height: 80px;
      }
  
      // Uploade image section
      .uploadImageSection {
        display: flex;
        flex-direction: column;
        width: 100%;
  
        .imageContents {
          input {
            min-width: 10rem;
            border: none;
            height: auto;
            padding: 0;
          }
  
          .iconInput {
            display: flex;
          }
  
          .imageStyle {
            width: 8rem;
            height: 8rem;
            border-radius: 50%;
            background-size: cover;
            background: white;
            object-fit: contain;
            border: 3px solid grey;
            margin-top: -3rem;
          }
        }
      }
    }
  
    .createRecordBtnStyle {
      background: white;
      color: #1f5492;
      border: 1px solid #1f5492;
      font-weight: 600 !important;
    }
  }
  

.ruleDefinitionModalBody {
    .modalBodySection {
        color: var(--text-01-body, #212529);
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.24px;
        padding: 0px 5px;

        .inputFieldSection {
            display: flex;
            font-size: 15px;
            margin: 1.5rem 0rem;

            .inputFieldInternalDiv{
                display: flex;
                flex-direction: column;

                input {
                    height: 32px;
                    border-radius: 5px;
                    border: 1px solid #ced4da;
                }

                label {
                    width: 236px;
                }

                .dropdownStyle {
                    border-radius: 5px;
                    background-color: #fff;
                    padding: 0 3px !important;
                    border: 1px solid #ced4da;
                    height: 32px;
                }

                .radioInputOuterDiv {
                    display: flex;
                    margin-top: 3rem;
                    align-items: center;
                    justify-content: space-between;

                    input {
                        width: 20px;
                        height: 20px;
                        margin: 0px 8px;
                        border-radius: 10px;
                        display: inline-grid;
                    }
                }

                .radioInputOuterDiv input[type='radio'] {
                    appearance: none;
                    -webkit-appearance: none;
                    width: 18px;
                    height: 18px;
                    margin: 0px 8px;
                    border-radius: 10px;
                    display: inline-grid;
                    border: 3px solid #ced4da;
                    background-color: white;
                }

                .radioInputOuterDiv input[type='radio']:checked {
                    background-color: #1f5492;
                }
            }
        }
    }
}

.ruleDefinitionPage {
    height: 89vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    margin-right: 20px;


    .searchBtnStyle {
        background: #EB5757;
        border: 1px solid #EB5757;
        color: #FFF;
        border: none;
        border-radius: 3px;
        color: #fff;
        font-size: 17px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.02em;
        text-align: center;
    }

    .keywordSearchBtn {
        border: none;
        border-radius: 3px;
        background: #1f5492;
        color: #fff;
        font-size: 17px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.02em;
        text-align: center;
    }
}

.ruleDefinitionPageContent {
    margin-top: 2rem;
    margin-left: 1.8rem;

    .pageTitle{
        margin-top: 0px;
    }
    .searchSection {
        color: #494440;
        font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
        font-weight: 700;
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 0.5em;
        margin-top: 1.5em;
    }
    .keywordSearchTitle {
        color: #2e2c2a;
        font-size: 16.603px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.253px;
        padding-bottom: 4px;
    }
    .searchOuterContainer {
        display: flex;
        justify-content: space-between;
    }
    .createRecordBtnStyle{
        background: white;
        color: #1F5492;
        border: 1px solid #1F5492;
        font-weight: 600 !important;
    }
}

.errorStyle {
    font-size: 1.3rem;
    color: red;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.para{
display: hidden;}
.RuleTriggerMetadataInput{
  width: 100%;
  min-height: 10vh;
  // margin: 1.5rem 0 ;

}