.loginPage {
  width: 100%;
  height: 100vh;

  .loginHeader {
    background-color: #236430;
    height: 5vh;
  }

  .loginSection {
    height: 95.5vh;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    .tradeMarkContent {
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 1rem;
      margin-bottom: 11rem;

      .trademarkIcon {
        color: black;
        border-radius: 4.5rem;
        border: 1px solid rgba(35, 100, 48, 0.1);
        background: #fff;
        box-shadow: 0px 0.25rem 0.25rem 0px rgba(116, 116, 116, 0.04);
        width: 66%;
        height: 10vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .loginContent {
      margin-top: 10rem;

      .cardSection {
        background-color: #f5f7fa;

        .userIconOuterDiv {
          display: flex;
          justify-content: center;
          margin-top: -7rem;
        }

        .loginCard {
          width: 50rem;

          .inputFieldStyle {
            padding: 0.625rem;
            width: 100%;
            border: 0.063rem solid #ccc;
            border-radius: 0.25rem;
            height: 4rem;
          }

          .passwordContainer {
            position: relative;
            margin-top: 3rem;

            .togglePassword {
              position: absolute;
              top: 50%;
              right: 0.625rem;
              transform: translateY(-50%);
              cursor: pointer;
            }
          }

          .loginBtnStyle {
            background: #1f5492;
          }
          .disabledLoginBtnStyle {
            background: rgba(31, 84, 146, 0.5);
            pointer-events: none;
          }

          .buttonStyle {
            color: #fff;
            border-radius: 3px;
            border: none;
            width: 10rem;
            display: block;
            margin-top: 3rem;
          }

          .errorMessage {
            color: red;
            margin-bottom: 0px !important;
            justify-content: center;
            display: flex;
          }

          .cardContent {
            display: flex;
            flex-direction: column;
            padding: 1rem 3rem 2rem;
          }
        }
      }

      .inputLable {
        display: flex;
        align-items: flex-start;
        color: var(--text-01-body, #212529);
        font-family: Poppins;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.015rem;
      }
    }

    .oktaButtonStyle {
      color: #1f5492;
      background-color: white;
      border-radius: 3px;
      display: block;
      margin-top: 3rem;
    }

    .trademarkIconDiv {
      position: relative;
      height: 8rem;
      width: 28rem;
      background-color: #fff;
      border-radius: 41px;
      border: 2px solid #f3f3f3;
    }
    .trademarkImg {
      height: 5rem;
      width: 18rem;
      position: absolute;
      top: 1.5rem;
      left: 4.5rem;
    }
  }
}
.warningLabel{
  margin-top: 14px;
  font-size: 12px !important;
}