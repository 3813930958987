.k-listview.with-border {
  border: 0px solid #fff !important;
}

.k-listview.with-border2 {
  border: 0px solid #fff !important;
}

.SuspenseQueueContainer {
  display: grid;
  grid-template-columns: 95% 4%;
  // align-items: center;
  // justify-content: center;
  // flex: 1;
  // flex-direction: row;
  // flex-wrap: wrap;
  // align-items: center; // if you want to fill rows left to right
  // min-height: 60vh;
  padding-left: 1em;
  position: relative;
}

.left-container {
  height: 90%;
  width: 92%; // is 50% of container width
  margin-right: 2%;
  position: relative;
}

.right-container {
  // height: 50%;
  width: 7%; // is 50% of container width
  position: relative;
}
.suspenseQueueMinimizedListOpen {
  position: absolute;
  top: 6%;
  right: 0;
  border: 2px solid #bcb9b9 !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0rem 0.188rem 0.375rem, rgba(0, 0, 0, 0.23) 0rem 0.188rem 0.375rem;
  // z-index: 999;
}

.suspenseQueueMinimizedListHide {
  opacity: 0;
  display: none;
}
.storedInDRMSTotalListOpen {
  position: absolute;
  top: 40%;
  right: 0;
  border: 2px solid #bcb9b9 !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0rem 0.188rem 0.375rem, rgba(0, 0, 0, 0.23) 0rem 0.188rem 0.375rem;
  // z-index: 999;
}

.listStyleAfterExpand {
  position: absolute;
  top: 5%;
  right: 0;
  border: 2px solid #bcb9b9 !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0rem 0.188rem 0.375rem, rgba(0, 0, 0, 0.23) 0rem 0.188rem 0.375rem;
  // z-index: 999;
}

.noListDataStyle {
  font-size: 14;
  margin-top: 3em;
  text-align: center;
  // width: 55rem;
}

.storedInDRMSTotalHide {
  opacity: 0;
  display: none;
}

.half-containers {
  // flex: 0 0 100%;
  // max-height: 50%;
  // min-height: 50%;
  max-width: 100%;
  // padding: 0.5em;
  overflow: hidden;
}
.expandStoredDrimsList {
  height: 75rem;
  width: 100rem;
  top: 25%;
}
.minimizeExpandStoredDrimsList {
  height: 35rem;
  width: 75rem;
}
.expandSuspenseQueue {
  height: 64rem;
  width: 100rem;
}
.minimizeSuspenseQueue {
  height: 35rem;
  width: 75rem;
}
.upload-btn {
  width: 6.5em;
}

.submit-processing-btn {
  width: 12em;
}

.k-panelbar-content .k-content {
  background-color: black;
}

.unprocessed-file-name {
  font-size: 14px;
  margin-bottom: 0;
  width: 100%;
  word-break: break-word;
}

.unprocessed-upload-date {
  margin-top: 1em;
  font-size: 12px;
  float: right;
}

.minimized {
  min-height: 0vh;
}

.filter-section-open {
  min-height: 30vh;
}

.unprocessed-section-open {
  min-height: 40vh;
}

.float-right {
  float: right;
}

.chevron {
  margin-top: 10px;
  bottom: 0;
  font-size: 20px;
}

.suspense-queue-icon {
  margin-right: 10px;
  font-size: 25px;
}

.no-list-data {
  padding: 6rem;
  font-size: 14;
  margin-top: 3em;
  text-align: center;
  height: 25rem;
  width: 55rem;
  background-color: white;
  border: solid 2px rgb(209, 205, 205);
  position: absolute;
  top: 0%;
  right: 0;
  z-index: 999;
}
.no-list-datadrims {
  padding: 7rem;
  font-size: 14;
  margin-top: 3.5em;
  text-align: center;
  height: 25rem;
  width: 55rem;
  background-color: white;
  border: solid 2px rgb(209, 205, 205);
  position: absolute;
  top: 45%;
  right: 0;
  z-index: 999;
}

.thumbnail {
  height: 100%;
  border: solid 1px rgb(188, 185, 185) !important;
}

.unprocessed-upload-date {
  font-size: 10px;
}

.unprocessed-file-name {
  .css-2b097c-container {
    min-width: 150px !important;
  }
}

.css-26l3qy-menu {
  width: 350px !important;
}

.css-1w9j89e-menu {
  width: 350px !important;
}

.selectSection {
  display: flex;
  // justify-content: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.customHeightUploadedQueue {
  height: 200px !important;
}

.text-trim {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.tabsStyle {
  font-size: 0.9em;
}

.tabDefaultColor {
  color: #000;
}

.highlightedText {
  color: #000;
  font-weight: 600;
}

.buttonsOuterDiv {
  border: 1px solid black;
  display: flex;
}

.selected-thing-btn {
  border-radius: 0 !important;
  cursor: default !important;
  font-size: 1.7rem;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', sans-serif;
  font-weight: 700;
  border-radius: 0.4rem;
  padding: 1rem;
  background-color: #afccf3;
  display: inline-block;
  color: black;
  white-space: nowrap;
  width: 40rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.tabOuterDiv {
  margin-top: 2px !important;
  display: flex;
}

.pageTabOuterDiv {
  margin-top: 7px !important;
  display: flex;
}

.buttonsOuterDiv {
  img {
    cursor: pointer;
  }
}

.fileHeader {
  background-color: lightgrey;
  display: flex;
  justify-content: space-between;
  padding: 1px 5px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  align-items: center;
}

.selectedFileHeader {
  display: flex;
  justify-content: space-between;
  padding: 1px 5px;
  font-size: 13px;
  font-weight: 600;
  margin-top: 5px;
  cursor: pointer;
  // border: 3px solid #53cbff;
  background-color: #afccf3;
  border: 3px solid #6291a5;
  align-items: center;
}

.objectNameStyle {
  background: #e8f0fe;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 10%;
  cursor: pointer;
}

.selectedObjectNameStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 10%;
  cursor: pointer;
  background-color: #afccf3;
  border: 3px solid #6291a5;
}

.objectName {
  transform: rotate(-90deg);
  // white-space: nowrap;
  width: 150px;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

.thumbnail1 {
  height: 100%;
  width: 230px;
}

.pageViewTab {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.filesList {
  height: calc(55vh);
  position: relative;
  overflow-y: auto;
}

.pageViewSelectSection {
  display: flex;
  // justify-content: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.objectTypesList {
  font-size: 14px;
  margin-bottom: 0;
  // width: 100%;
  word-break: break-word;
  position: absolute;
  left: 6rem;
  padding: 3rem;
  background-color: white;
  border: 0.063rem solid black;
  z-index: 9;
  box-shadow: rgba(0, 0, 0, 0.16) 0rem 0.188rem 0.375rem, rgba(0, 0, 0, 0.23) 0rem 0.188rem 0.375rem;

  .css-2b097c-container {
    width: 27%;
  }
}

.arrowStyle {
  position: absolute;
  top: 0;
  margin-top: 10px;
  cursor: pointer !important;
  font-size: 2.2rem;
}

.selectedFileSection {
  background: #afccf3;
}

.selectedThumbnail {
  border: 3px solid #6291a5;
  // height: 100%;
  width: 200px;
  height: auto;
  margin-right: 10px;
  flex-shrink: 0;
  transition: transform 0.3s ease; /* Smooth transition for the rotation */
}

.rotatedImage {
  border: 3px solid #6291a5;
  // height: 100%;
  width: 200px;
  height: auto;
  margin-right: 30px !important;
  margin-left: 20px !important;
  flex-shrink: 0;
  transition: transform 0.3s ease; /* Smooth transition for the rotation */
}

.pageThumbnail {
  // height: 100%;
  width: 200px;
  height: auto;
  margin-right: 10px;
  flex-shrink: 0;
}

.activeIconStyle {
}

.disabledIconStyle {
  opacity: 0.2;
  pointer-events: none;
}

.imageOuterDiv {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0px 10px;
}
.selectedObjectImgStyle {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0px 10px;
  cursor: pointer;
  background-color: #afccf3;
  border: 3px solid #6291a5;
}

.fileInputName {
  height: 25px;
  margin: 3px;
}
