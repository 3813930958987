.glbRuleDefinitionModalStyle,
.glbDispositionModalStyle {
  .modal-content,
  .modal-dialog {
    border: none;
    width: 600px;
    border-radius: 9px;
  }
}

.glbMetadataModal {
  .modal-content {
    border-radius: 9px !important;
  }
}

.glbRuleDefinitionTable,
.glbRoutingStepsTable,
.glbOrchestrationTable,
.glbElementTable,
.glbElementGroupTable,
.glbObjectDefinitionTable,
.glbRedactionActorTable,
.glbRedactionReasonTable,
.glbPackagerConfigurationTable,
.glbBatchXwalkTable,
.glbSystemConfigurationTable,
.glbBUOWDisplayNameTable,
.glbUsersTableTable,
.glbRolesTable,
.glbPermissionsTableTable,
.glbElementGroupXwalkTable,
.glbbuowTable,
.glbUserPreferenceTable,
.glbUserPreferenceTable,
.glbTableInBasketTable,
.glbTableUnitOfWork,
.glbTableRedactionHistory,
.glbObjectHierarchyTable,
.glbRedactionDefinitionTable,
.glbDashboardIndicatorsTable,
.glbDashboardIndicatorsGroupTable,
.glbDashboardIndicatorsDataSetCardXRefTable,
.glbDashboardGroupCardXRefTable,
.glbDashboardCardBenchmarkDisplayDataTable,
.glbSecRoleDashboardCardXRefTable,
.glbIndicatorsDataSetTable,
.glbRoutingConditionsTable,
.glbTableRedactionHistory,
.glbBatchDefinitionTable,
.glbPackagerPreferredOrderTable {
  .k-grid th {
    padding: 0px 0px 0px 0px !important;
    background-color: #236430;
    align-items: center;
    vertical-align: middle !important;
  }

  .k-grid td {
    // text-align: center !important;
    vertical-align: middle;
  }

  .k-grid-header .k-header > .k-link {
    padding: 5px 0px !important;
    border-right: 2px solid #fff;
  }

  .k-grid-header .k-header {
    border-left-width: 0;
    border-left: 0.5px solid #fff;
    border-right: 0.5px solid #fff;
  }
  /* Sticky header styling */
  .k-grid-header {
    position: sticky;
    top: 0;
    z-index: 10;
    border-top: solid 10px #f7f7f7;
  }
}

.glbSearchTable {
  .k-grid th {
    padding: 0px 0px 0px 0px !important;
    background-color: #12a5e4;
    align-items: center;
    vertical-align: middle !important;
  }

  .k-grid td {
    // text-align: center !important;
    vertical-align: middle;
  }

  .k-grid-header .k-header > .k-link {
    padding: 5px 0px !important;
    border-right: 2px solid #fff;
  }

  .k-grid-header .k-header {
    border-left-width: 0;
    border-left: 0.5px solid #fff;
    border-right: 0.5px solid #fff;
  }
}

.k-grid tr td {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  text-align: left !important;
  border: solid 1px #ebebeb !important;
}
.k-table-md {
  font-size: 14px !important; /* Or any value you prefer */
  // line-height: 1.5 !important; /* Or any value you prefer */
}

//  Routing Steps , Users Table
.glbRoutingStepsModalStyle {
  .modal-dialog {
    max-width: 782px !important;
  }

  .modal-content {
    border-radius: 9px !important;
  }
}
.glbBatchDefinitionModalStyle {
  .modal-dialog {
    max-width: 990px !important;
    overflow-y: auto !important;
  }

  .modal-content {
    border-radius: 9px !important;
  }
}
.glbUsersTableModalStyle {
  .modal-dialog {
    max-width: 782px !important;
  }

  .modal-content {
    border-radius: 9px !important;
  }
  .k-switch-thumb {
    width: 26px;
    height: 26px;
  }
  .k-switch-on .k-switch-thumb {
    border: 2px solid #0075ff;
    background-color: white;
  }
  .k-switch-on:hover .k-switch-thumb,
  .k-switch-on.k-state-hover .k-switch-thumb {
    border: 2px solid #0075ff;
    background-color: white;
  }
  .k-switch-off .k-switch-thumb {
    border: 2px solid #c7c7cc;
    background-color: white;
  }
  .k-switch-off:hover .k-switch-thumb,
  .k-switch-off.k-state-hover .k-switch-thumb {
    border: 2px solid #c7c7cc;
    background-color: white;
  }

  .k-switch-on .k-switch-track {
    color: #ffffff;
    background-color: #0075ff;
    border-color: #0075ff;
    outline-color: #0075ff;
  }
  .k-switch-off .k-switch-track {
    color: #ffffff;
    background-color: #c7c7cc;
    border-color: #c7c7cc;
    outline-color: #c7c7cc;
  }
  .k-switch-on:hover .k-switch-track,
  .k-switch-on.k-state-hover .k-switch-track {
    color: #ffffff;
    background-color: #0075ff;
    border-color: #0075ff;
    outline-color: #0075ff;
  }
  .k-switch-off:hover .k-switch-track,
  .k-switch-off.k-state-hover .k-switch-track {
    color: #ffffff;
    background-color: #acacb2;
    border-color: #acacb2;
    outline-color: #acacb2;
  }
}

.glbMakeItDefaultModalStyle {
  .modal-dialog {
    max-width: 430px !important;
  }

  .modal-content {
    border-radius: 9px !important;
  }
}

.glbBuowModalStyle {
  .modal-dialog {
    max-width: 650px !important;
  }

  .modal-content {
    border-radius: 9px !important;
  }
}
.glbPackagerPreferredOrderModalStyle {
  .modal-dialog {
    max-width: 600px !important;
    max-height: 800px !important;
  }

  .modal-content {
    border-radius: 9px !important;
  }
}

// Orchestration, Object Defination, Redaction Actor, Packager Configuration, Batch Xwalk, Redaction Reason, Redaction Defination && user preference
.glbOrchestrationModalStyle,
.glbElementsModalStyle,
.glbElementGroupModalStyle,
.glbObjectDefinitionModalStyle,
.glbRedactionActorModalStyle,
.glbPackagerConfigurationModalStyle,
.glbBatchXwalkModalStyle,
.glbRedactionReasonModalStyle,
.glbRedactionDefinitionModalStyle,
.glbDashboardIndicatorsModalStyle,
.glbDashboardIndicatorsGroupModalStyle,
.glbDashboardIndicatorsDataSetCardXRefModalStyle,
.glbDashboardGroupCardXRefModalStyle,
.glbDashboardCardBenchmarkDisplayDataModalStyle,
.glbSecRoleDashboardCardXRefModalStyle,
.glbIndicatorsDataSetModalStyle,
.glbRedactionHistoryModalStyle,
.glbRoutingConditionsModalStyle,
.glbUserPreferenceModalStyle,
.glbRedactionReferenceModalStyle {
  .modal-content,
  .modal-dialog {
    border: none;
    width: 600px;
    border-radius: 9px;
  }
}

// Roles Table
.glbRolesTableModalStyle {
  .modal-dialog {
    max-width: 600px !important;
  }

  .modal-content {
    border-radius: 9px !important;
  }
}
.glbRuleTriggerMetadataTableModalStyle {
  .modal-dialog {
    max-width: calc(100vw - 35vw) !important;
  }

  .modal-content {
    border-radius: 9px !important;
  }
}
// System Configuration , Permissions Table
.glbSystemConfigurationModalStyle,
.glbBUOWDisplayNameModalStyle,
.glbPermissionsTableModalStyle,
.glbElementGroupXwalkModalStyle {
  .modal-content,
  .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    border: none;
    width: 600px;
    border-radius: 9px;
  }

  .k-switch-on .k-switch-thumb-wrap .k-rounded-full {
    border: 2px solid #12b28c;
    background-color: white;
    outline: #12b28c;
  }

  .k-switch-on:hover .k-switch-thumb-wrap .k-rounded-full,
  .k-switch-on.k-state-hover .k-switch-thumb-wrap .k-rounded-full {
    border: 2px solid #12b28c;
    background-color: white;
    outline: #12b28c;
  }

  .k-switch-off .k-switch-thumb {
    border: 2px solid #c7c7cc;
    background-color: white;
    outline: #c7c7cc;
  }

  .k-switch-off:hover .k-switch-thumb,
  .k-switch-off.k-state-hover .k-switch-thumb {
    border: 2px solid #c7c7cc;
    background-color: white;
    outline: #c7c7cc;
  }

  .k-switch-on .k-switch-track {
    color: #ffffff;
    background-color: #12b28c;
    border-color: #12b28c;
    outline: #12b28c;
  }

  .k-switch-off .k-switch-track {
    color: #ffffff;
    background-color: #c7c7cc;
    border-color: #c7c7cc;
    outline: #c7c7cc;
  }

  .k-switch-on:hover .k-switch-track,
  .k-switch-on.k-state-hover .k-switch-track {
    color: #ffffff;
    background-color: #0e8d70;
    border-color: #0e8d70;
    outline: #0e8d70;
  }

  .k-switch-off:hover .k-switch-track,
  .k-switch-off.k-state-hover .k-switch-track {
    color: #ffffff;
    background-color: #acacb2;
    border-color: #acacb2;
    outline: #acacb2;
  }
}
.glbElementGroupXwalkModalStyle {
  .modal-content,
  .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    border: none;
    width: calc(100vw - 30vw);
    border-radius: 9px;
  }
}
.glbSystemConfigurationModalStyle,
.glbSystemConfigurationModalStyleCustom {
  .modal-content,
  .modal-dialog {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    border: none;
    width: 90vw !important;
    border-radius: 9px !important;
  }
}

.requiredErrorMsgStyle {
  // font-size: 1.3rem;
  // color: red;
  color: red;
  font-style: italic;
  font-size: 12px;
  margin-top: 4px;
}

.k-grid-content {
  overflow-x: scroll;
}

.k-window-actions {
  border: none;
}
.k-window-titlebar-actions {
  border: none;
}

.searchTable {
  .k-grid .k-cell-inner > .k-link:hover {
    // background-color: rgb(35, 100, 48) !important;
    background-color: inherit;
    font-weight: bold !important;
    color: white !important;
  }

  .k-grid-filter {
    display: none;
  }

  .k-grid-filter:hover {
    background-color: #25a4db !important;
    color: white !important;
  }

  .k-grid-header .k-header {
    border-color: #f6f6f6 !important;
  }

  .k-grid .k-table {
    display: table !important;
  }
}

// Start:- New search page scss
.k-tabstrip-top > .k-tabstrip-items-wrapper > .k-tabstrip-items {
  width: 100%;
  background-color: #ebebeb;
  border-radius: 6px;
  padding: 1rem 1.8rem 1rem 1rem;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: flex;
  justify-content: space-between;
  font-size: 1.3em;
}

.k-tabstrip-items .k-link {
  font-size: 1.5rem;
  color: #222 !important;
  font-weight: 800;
}

.k-tabstrip-items-wrapper .k-item.k-state-active,
.k-tabstrip-items-wrapper .k-item.k-state-selected {
  color: #000;
  background-color: #ebebeb !important;
  border-bottom: 3px solid #12a5e4 !important;
}

.k-tabstrip-items-wrapper:hover .k-item.k-state-active:hover,
.k-tabstrip-items-wrapper:hover .k-link:hover {
  background-color: #dee5ef !important;
}

.k-tabstrip-content,
.k-tabstrip > .k-content {
  background-color: #fff;
  border: 1px solid #a7a7a7 !important;
  border-top: none !important;
  border-radius: 0px 0px 10px 10px !important;
  margin: 0px;
  height: 500px !important;
}

.glbSearchPageModalStyle {
  .modal-dialog {
    max-width: 90vw !important;
  }

  .modal-content {
    border-radius: 9px !important;
  }
}

.glbUpdateFileCodeModalStyle {
  .modal-content,
  .modal-dialog {
    border: none;
    width: 80vw;
    border-radius: 9px;
  }
}

.glbUpdateHoldModalStyle {
  .modal-content,
  .modal-dialog {
    border: none;
    // width: 80vw;
    border-radius: 9px;
  }
}

// End:- New search page scss
.searchNoteStyle {
  font-size: 15px;
  font-weight: 400;
  font-style: italic;
}

.actionColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textCenter {
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 41px;
}

.glbModalHeader {
  .k-window-title {
    color: #484440;
    font-size: 27px;
    font-weight: 600;
    letter-spacing: 0.44px;
    padding-top: 0px;
  }

  .k-dialog-titlebar {
    border-bottom: 1.5px solid #d9d9d9 !important;
    padding: 15px 10px;
  }

  .k-window-actions {
    border: 3px solid #eb5757;
    border-radius: 50%;
    padding: 4px !important;
  }
  .k-window-titlebar-actions {
    border: 3px solid #eb5757;
    border-radius: 50%;
    padding: 4px !important;

    svg {
      color: #eb5757 !important;
      transform: scale(1.3);
    }
  }

  .k-window-titlebar-actions .k-button-icon {
    color: #eb5757 !important;
    font-size: 30px !important;
  }
  .k-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid #aaa;
    // border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: transparent;
  }
  .glbDatePicker {
    .k-input {
      border-color: inherit;
      border-width: 0;
      box-sizing: border-box;
      display: flex;
      flex: 1 1 auto;
      flex-flow: row nowrap;
      overflow: hidden;
      position: relative;
      width: 100%;
      font-size: 2rem;

      .k-button {
        background-color: #f5f5f5;
        background-image: linear-gradient(#0000, #00000005);
        border-color: #00000014;
        align-items: center;
        border-width: 1px;
        border-inline-start-width: 0;
        border-style: solid;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        flex: 0 0 auto;
        flex-flow: row nowrap;
        justify-content: center;
        outline: 0;
        padding: 4px;
        border-radius: 0px;
        width: calc(1.42857em + 8px);
      }
    }
  }
  .k-dropdownlist {
    display: flex;
    .k-button .k-icon svg {
      transform: scale(1.5);
    }
    .k-input-inner .k-input-value-text {
      font-size: 12px;
    }
  }

  // Radio button style
  .k-radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid #aaa;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: transparent;
  }
  .k-radio:checked {
    background-color: #007bff;
    border-color: #007bff;
  }
  .k-radio:checked::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: white;
  }

  .k-icon {
    font-size: 25px;
  }
  .k-window-content {
    overflow-x: hidden;
  }
  .k-dropdownlist .k-input-inner .k-input-value-text {
    font-size: 1.5em;
  }
}
//edit transfer modal
.glbEditTransferModal {
  .k-dropdownlist .k-input-inner .k-input-value-text {
    font-size: 1.3em;
  }
  .k-tabstrip-items-wrapper .k-item.k-active,
  .k-tabstrip-items-wrapper .k-item.k-state-selected {
    background-color: #ebebeb !important;
    border-bottom: 3px solid #12a5e4 !important;
    border-bottom-color: #12a5e4 !important;
  }
  .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-active {
    border-bottom-color: #12a5e4 !important;
  }
}

//edit hold modal
.glbEditHoldModal {
  .k-dropdownlist .k-input-inner .k-input-value-text {
    font-size: 1.3em;
  }
  .k-tabstrip-items-wrapper .k-item.k-active,
  .k-tabstrip-items-wrapper .k-item.k-state-selected {
    background-color: #ebebeb !important;
    border-bottom: 3px solid #12a5e4 !important;
    border-bottom-color: #12a5e4 !important;
  }
  .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-active {
    border-bottom-color: #12a5e4 !important;
  }
}

.glbRecordScheduleModal {
  .k-window-title {
    color: #484440;
    font-size: 27px;
    font-weight: 600;
    letter-spacing: 0.44px;
    padding-top: 0px;
  }
  .k-dialog-titlebar {
    border-bottom: 1.5px solid #d9d9d9 !important;
    padding: 15px 10px;
  }
  .k-window-actions {
    border: 3px solid #eb5757;
    border-radius: 50%;
    padding: 4px !important;
  }
  .k-window-titlebar-actions {
    border: 3px solid #eb5757;
    border-radius: 50%;
    padding: 4px !important;

    svg {
      color: #eb5757 !important;
      transform: scale(1.3);
    }
  }
  .k-button-icon {
    color: #424242 !important;
  }
  .k-dropdownlist {
    display: flex;
    .k-button .k-icon svg {
      transform: scale(1.5);
    }
    .k-input-inner .k-input-value-text {
      font-size: 12px;
    }
  }
  .k-icon {
    font-size: 25px;
  }
  .k-window-content {
    height: 78vh;
    overflow-x: hidden;
  }
  // Radio button style
  .k-radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid #aaa;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: transparent;
  }
  .k-radio:checked {
    background-color: #007bff;
    border-color: #007bff;
  }
  .k-radio:checked::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: white;
  }
}
.glbRecordScheduleSearchModal {
  .k-window-title {
    color: #484440;
    font-size: 27px;
    font-weight: 600;
    letter-spacing: 0.44px;
    padding-top: 0px;
  }
  .k-dialog-titlebar {
    border-bottom: 1.5px solid #d9d9d9 !important;
    padding: 15px 10px;
  }
  .k-window-actions {
    border: 3px solid #eb5757;
    border-radius: 50%;
    padding: 4px !important;
  }
  .k-window-titlebar-actions {
    border: 3px solid #eb5757;
    border-radius: 50%;
    padding: 4px !important;

    svg {
      color: #eb5757 !important;
      transform: scale(1.3);
    }
  }
  .k-window-titlebar-actions .k-button-icon {
    color: #eb5757 !important;
    font-size: 30px !important;
  }
  .k-icon {
    font-size: 25px;
  }
  .k-window-content {
    height: 64vh;
    overflow-x: hidden;
  }
  .k-radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid #aaa;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: transparent;
  }
  .k-radio-wrap {
    flex: none;
    display: inline-flex;
    flex-flow: row nowrap;
    gap: 0;
    align-items: center;
    vertical-align: middle;
    position: relative;
  }
  .k-radio:checked {
    background-color: #007bff;
    border-color: #007bff;
  }
  .k-radio:checked::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: white;
  }

  .k-dropdownlist .k-input-inner .k-input-value-text {
    font-size: 1.5em;
  }
}
// document version error modal
.glbErrorDocModal {
  .modal-dialog {
    max-width: 460px !important;
  }

  .modal-content {
    border-radius: 9px;
  }
}
.glbSearchHoldModal {
  .k-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid #e9e5e5;
    // border-radius: 50%;
    width: 18px;
    height: 18px;
    background-color: transparent;
  }
}
.glbSearchUpdateHoldForm {
  .k-dropdownlist .k-input-inner .k-input-value-text {
    font-size: 1.5em;
  }
}
.glbSearchTransferModal {
  .k-radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid #e9e5e5;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: transparent;
  }
  .k-radio:checked {
    background-color: #007bff;
    border-color: #007bff;
  }
  .k-radio:checked::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: white;
  }
}
.validation-message {
  color: red;
  font-style: italic;
  font-size: 12px;
  margin-top: 4px;
}

.success-message {
  color: #0fa14c;
  font-size: 12px;
  margin-top: 4px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
}

.primaryBtnStyle {
  border: none;
  border-radius: 3px;
  background: #1f5492;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  cursor: pointer;
}
.loaderText {
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 500;
}
.header-sort-icon {
  margin-left: 10px;
}

.customSwitchOn {
  /* Handle (Switch Button) Styling */
  .k-switch-on .k-switch-thumb,
  .k-switch-on .k-switch-handle {
    /* Support both new and old React */
    background-color: darkgray !important;
    border: 2px solid white !important; /* Ensure the border stays white */
  }

  /* Handle Hover State */
  .k-switch-on:hover .k-switch-thumb,
  .k-switch-on:hover .k-switch-handle {
    background-color: darkgray !important; /* Support both new and old React */
    border: 2px solid white !important; /* Ensure border remains white */
  }

  /* Track (Background) Styling */
  .k-switch-on .k-switch-track,
  .k-switch-on .k-switch-container {
    /* Support both new and old React */
    background-color: grey !important;
  }
}

.buowPageRestrictionToggle {
  .k-switch-on .k-switch-handle {
    border: 2px solid rgba(68, 114, 196, 1);
    background-color: white;
  }
  .k-switch-off .k-switch-thumb-wrap .k-rounded-full::before {
    content: '+';
    font-size: 2em;
    position: absolute;
    top: 10%;
    left: 25%;
  }
  .k-switch-off .k-switch-thumb-wrap .k-rounded-full.active::before {
    content: '';
  }
  .k-switch-on .k-switch-thumb-wrap .k-rounded-full::before {
    content: '-';
    color: black;
    font-size: 2.2em;
    position: absolute;
    top: 4%;
    left: 35%;
  }
  .k-switch-on .k-switch-thumb-wrap .k-rounded-full.active::before {
    content: '';
  }
  .k-switch-on:hover .k-switch-handle,
  .k-switch-on.k-state-hover .k-switch-handle {
    border: 2px solid rgba(68, 114, 196, 1);
    background-color: white;
  }
  .k-switch-off .k-switch-handle {
    border: 2px solid #c7c7cc;
    background-color: white;
  }
  .k-switch-off:hover .k-switch-handle,
  .k-switch-off.k-state-hover .k-switch-handle {
    border: 2px solid white;
    background-color: white;
  }

  .k-switch-on .k-switch-track {
    color: #ffffff;
    background-color: rgba(68, 114, 196, 1);
    font-weight: 700;
  }
  .k-switch-off .k-switch-track {
    color: black;
    background-color: white;
    font-weight: 700;
  }
  .k-switch-on:hover .k-switch-track,
  .k-switch-on.k-state-hover .k-switch-track {
    color: #ffffff;
    background-color: rgba(68, 114, 196, 1);
  }
  .k-switch-off:hover .k-switch-track,
  .k-switch-off.k-state-hover .k-switch-track {
    color: #ffffff;
    background-color: white;
    color: black;
  }
}
.glbInfoPopupStyle {
  .modal-dialog {
    min-width: 1000px !important;
    // min-height: 950px !important;
    margin: 0 auto;
    // height: 100%;
  }

  .modal-content {
    border-radius: 9px !important;
    min-width: 1000px !important;
    // height: 280px;
    overflow: hidden;
  }
}
.firstEleFocused:focus {
  border-color: #2684ff;
  box-shadow: 0 0 0 1px #2684ff;
}

// Node version changes css
.k-grid {
  display: block !important; /* Prevent flexbox styles */
  border-spacing: 0 !important; /* Reset spacing issues */
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
}
.k-table-md {
  font-size: 14px !important; /* Or any value you prefer */
  line-height: 1.5 !important; /* Or any value you prefer */
}
.k-grid td {
  padding: 8px 12px !important;
}
.k-grid td,
.k-grid th {
  border: 1px solid #ddd !important; /* Restore previous border style */
}
.k-pager-info {
  margin-right: 20px;
  font-size: 14px;
  color: #333;
}
.k-pager-nav {
  display: flex;
  gap: 10px;
  color: #0068ad !important; // to match the enable arrow colour in pagination
}

.k-disabled {
  color: gray !important; // to match the disabled arrow colour in pagination
}

.k-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 12px;
  // border-radius: 4px; commented because there is no border radius applied to current pagination
  cursor: pointer;
  font-size: 14px !important;
}
.k-button:hover {
  background-color: #0056b3;
}
.k-button:disabled {
  background-color: #e0e0e0;
  color: #ccc;
  cursor: not-allowed;
}
.k-animation-container ul li:hover {
  background-color: #f4f4f4 !important;
  cursor: pointer;
}
.k-animation-container ul li {
  font-size: 14px;
}
.k-animation-container div.k-selected {
  background-color: #0068ad !important;
  cursor: pointer;
}
.k-animation-container div {
  font-size: 14px;
}

.k-grid-norecords-template {
  border: none !important;
  text-align: left !important;
  margin-left: 1em !important;
  height: 2.5em !important;
  line-height: 2.5em !important;
}

.k-textarea .k-input-inner {
  width: 100% !important;
  font-size: 14px !important;
}
.k-textarea:has(.k-input-inner[style*='width']) {
  width: 100% !important;
}

.glbTransferForm {
  .k-dropdownlist {
    height: 5.5em !important;
  }

  .k-numerictextbox {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    display: inline-flex;
    flex-flow: row nowrap;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.4285714286;
    outline: 0;
    overflow: hidden;
    position: relative;
    text-align: start;
    transition: all 0.1s ease;
    vertical-align: middle;
    white-space: nowrap;
    height: 2.8em;

    .k-input-spinner {
      align-items: stretch;
      border-width: 0;
      border-inline-start-width: 0;
      border-style: solid;
      cursor: pointer;
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      justify-content: center;
      outline: 0;
      padding: 0px 0px;
      text-align: center;
      width: calc(1.42857em + 8px);
      background-color: #f5f5f5;
      background-image: linear-gradient(#0000, #00000005);
      border-color: #00000014;
      color: #424242;
      border-radius: 0px;
      gap: 5px;
    }
    svg {
      transform: scale(1.5);
    }
  }
  .k-form-hint {
    font-size: 12px;
  }
  .k-checkbox {
    width: 16px;
    height: 16px;
    margin-right: 2px;
  }
}

.editTransferTab {
  .k-animation-container {
    width: 100%;
  }
}
.k-grid-norecords-template {
  width: 90% !important;
}

.glbPreviewDocModal {
  .k-tabstrip-items-wrapper .k-item.k-active,
  .k-tabstrip-items-wrapper .k-item.k-state-selected {
    background-color: #ebebeb !important;
    border-bottom: 3px solid #12a5e4 !important;
    border-bottom-color: #12a5e4 !important;
  }
}
