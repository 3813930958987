.elementGroupPage {
    height: 89vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    padding-right: 20px;
}

.elementGroupPageContent {
    margin-top: 2rem;
    margin-left: 1.8rem;

    .pageTitle {
        margin-top: 0px;
    }

    .searchSection {
        color: #494440;
        font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
        font-weight: 700;
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 0.5em;
        margin-top: 1.5em;
    }

    .keywordSearchTitle {
        color: #2e2c2a;
        font-size: 16.603px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.253px;
        padding-bottom: 4px;
    }

    .searchOuterContainer {
        display: flex;
        justify-content: space-between;
    }

    .createRecordBtnStyle {
        background: white;
        color: #1F5492;
        border: 1px solid #1F5492;
        font-weight: 600 !important;
    }
}

.elementGroupTable {
    max-width: calc(100vw - 12rem);

    td {
        text-align: center !important;
    }
}


.elementGroupModal {
    .modalHeader {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .modalHeaderTitle {
            margin: 0;
            font-size: 24px;
            font-weight: 550;
            color: #484440;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.44px;
            font-family: sans-serif;
        }

        .modalHeaderIcon {
            cursor: pointer;
        }
    }

    .elementGroupModalBody {
        padding: 1.5rem;

        .modalBodySection {
            color: var(--text-01-body, #212529);
            font-family: sans-serif;
            // font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.24px;
            padding: 0px 5px;
        }

        .buowNameField {
            width: auto;
            display: flex;
            margin: 1rem 0rem;
            flex-direction: column;
            justify-content: space-between;

            label {
                display: flex;
                margin-bottom: 0.2rem !important;
            }

            input {
                display: block;
                width: 100%;
                height: 32px;
                border: 1px solid #ced4da;
                border-radius: 4px;
            }
        }

        .inputFieldSection {
            // height: 80px;
            display: flex;
            font-size: 15px;
            margin: 1.5rem 0rem;
            gap: 2rem;

            .inputFieldInternalDiv {
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            label {
                display: flex;
                margin-bottom: 0.2rem !important;
            }

            input {
                display: block;
                width: 100%;
                height: 32px;
                border: 1px solid #ced4da;
                border-radius: 4px;
            }

            select {
                display: block;
                width: 100%;
                height: 32px;
                background-color: #fff;
                font-weight: 400;
                border: 1px solid #ced4da;
                border-radius: 4px;
            }

            .radioBtnContainer {
                display: flex;
                align-items: center;
            }

            .dropdownStyle {
                border-radius: 5px;
                background-color: #fff;
                padding: 0 3px !important;
                border: 1px solid #ced4da;
                height: 32px;
            }

            .radioInputOuterDiv {
                display: flex;
                margin-top: 3rem;
                align-items: center;

                input {
                    width: 20px;
                    height: 20px;
                    margin: 0px 8px;
                    border-radius: 10px;
                    display: inline-grid;
                }
            }

            .radioInputOuterDiv input[type='radio'] {
                appearance: none;
                -webkit-appearance: none;
                width: 18px;
                height: 18px;
                margin: 0px 8px;
                border-radius: 10px;
                display: inline-grid;
                border: 3px solid #ced4da;
                background-color: white;
            }

            .radioInputOuterDiv input[type='radio']:checked {
                background-color: #1f5492;
            }
        }

        .searchFieldDiv {
            flex: 1 0 20%;
            height: 80px;
        }
    }

    .createRecordBtnStyle {
        background: white;
        color: #1F5492;
        border: 1px solid #1F5492;
        font-weight: 600 !important;
    }
}

.elementGroupFooter {
    padding: 0px !important;

    .footerInternalDiv {
        width: 100%;
        height: 53px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .saveRecordBtn {
        background: #28A745 !important;
        color: white !important;
        border-color: transparent !important;
    }

    .cancelRecordBtn {
        background: white;
        color: #1F5492;
        border: 1px solid #1F5492;
    }
}

.requiredFieldError {
    color: #EB5757;
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}