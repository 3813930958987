.systemConfigurationPage {
  height: 89vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  padding-right: 20px;
}

.systemConfigurationPageContent {
  margin-top: 2rem;
  margin-left: 1.8rem;

  .pageTitle {
    margin-top: 0px;
  }

  .searchSection {
    color: #494440;
    font-family: 'Source Sans Pro', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0.5em;
    margin-top: 1.5em;
  }

  .keywordSearchTitle {
    color: #2e2c2a;
    font-size: 16.603px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.253px;
    padding-bottom: 4px;
  }

  .searchOuterContainer {
    display: flex;
    justify-content: space-between;
  }

  .createRecordBtnStyle {
    background: white;
    color: #1f5492;
    border: 1px solid #1f5492;
    font-weight: 600 !important;
  }
}

.systemConfigurationTable {
  max-width: calc(100vw - 12rem);

  td {
    text-align: center !important;
  }
}

.errorStyle {
  color: #eb5757;
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
}

.systemConfigurationModal {
  .modalHeader {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .modalHeaderTitle {
      margin: 0;
      font-size: 24px;
      font-weight: 550;
      color: #484440;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.44px;
      font-family: sans-serif;
    }

    .modalHeaderIcon {
      cursor: pointer;
    }
  }

  .systemConfigModalBody {
    padding: 1.5rem;

    .modalBodySection {
      color: var(--text-01-body, #212529);
      font-family: sans-serif;
      // font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.24px;
      padding: 0px 5px;
    }

    .inputFieldSection {
      // height: 80px;
      width: 100%;
      display: flex;
      // flex-wrap: wrap;
      font-size: 15px;
      margin: 1.5rem 0rem;
      // gap: 2rem;

      .inputFieldInternalDiv {
        display: flex;
        flex-direction: column;
        width: 48%;
        align-items: flex-start;
      }
      .inputFieldInternalDiv1 {
        display: flex;
        flex-direction: column;
        width: 30%;
        align-items: flex-start;
      }
      .radioBtnContainer {
        display: flex;
        align-items: center;
      }

      label {
        display: flex;
        margin-bottom: 0.2rem !important;
      }

      input {
        display: block;
        width: 100%;
        height: 32px;
        border: 1px solid #ced4da;
        border-radius: 4px;
      }

      select {
        display: block;
        width: 100%;
        height: 32px;
        background-color: #fff;
        font-weight: 400;
        border: 1px solid #ced4da;
        border-radius: 4px;
      }

      .radioBtnContainer {
        display: flex;
        align-items: center;
      }

      .dropdownStyle {
        border-radius: 5px;
        background-color: #fff;
        padding: 0 3px !important;
        border: 1px solid #ced4da;
        height: 32px;
      }

      .radioInputOuterDiv {
        display: flex;
        // margin-top: 3rem;
        align-items: center;

        .radioBtnText {
          // padding-left: 0px;
          // padding-right: auto;
        }

        input {
          width: 20px;
          height: 20px;
          margin: 0px 8px;
          border-radius: 10px;
          display: inline-grid;
        }
      }

      .toggleFieldInternalDiv {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 95%;
        border: 1px solid #d1d4d7;
        border-radius: 9px;
        padding: 1.2rem 0.6rem;
      }
      .toggleFieldInternalDiv1 {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        border: 1px solid #d1d4d7;
        border-radius: 9px;
        padding: 1.2rem 1rem;
      }

      .radioInputOuterDiv input[type='radio'] {
        appearance: none;
        -webkit-appearance: none;
        width: 18px;
        height: 18px;
        margin: 0px 8px;
        border-radius: 10px;
        display: inline-grid;
        border: 3px solid #ced4da;
        background-color: white;
      }

      .radioInputOuterDiv input[type='radio']:checked {
        background-color: #1f5492;
      }
    }

    .inputFieldSectionAddOn {
      align-items: flex-start;
    }

    .searchFieldDiv {
      flex: 1 0 20%;
      height: 80px;
    }

    // Uploade image section
    .uploadImageSection {
      display: flex;
      flex-direction: column;
      width: 100%;

      .imageContents {
        input {
          min-width: 10rem;
          border: none;
          height: auto;
          padding: 0;
        }

        .iconInput {
          display: flex;
        }

        .imageStyle {
          width: 8rem;
          height: 8rem;
          border-radius: 50%;
          background-size: cover;
          background: white;
          object-fit: contain;
          border: 3px solid grey;
          margin-top: -3rem;
        }
      }
    }
  }

  .createRecordBtnStyle {
    background: white;
    color: #1f5492;
    border: 1px solid #1f5492;
    font-weight: 600 !important;
  }
}

.orchestrationFooter {
  padding: 0px !important;

  .footerInternalDiv {
    width: 100%;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .saveRecordBtn {
    background: #28a745 !important;
    color: white !important;
    border-color: transparent !important;
  }

  .cancelRecordBtn {
    background: white;
    color: #1f5492;
    border: 1px solid #1f5492;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.buowContainer {
  display: flex !important;
  align-items: center;
  flex-direction: row !important;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #d1d4d7;
  width: 100%;
}
.buowList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: auto;
  vertical-align: middle;
  list-style: none;
  margin: 0 !important;
  // max-width: 350px;
  max-height: 10vh;
  flex-wrap: wrap;
  width: 100% !important;
}
.indexSheetList {
  border: 1px solid #d1d4d7;
  border-radius: 7px;
  height: fit-content !important;
  margin: 0 10px !important;
  padding: 0 !important;
  overflow: hidden;
}
.listHead {
  font-weight: 800;
  text-align: center;
  padding: 10px 15px;
  border-bottom: 1px solid #d1d4d7;
  display: flex;
  justify-content: space-between;
}
.indexList {
  padding: 10px;
  height: 200px;
  overflow-y: scroll;
  list-style: none;
}
.indexListPill {
  padding: 5px;

  margin: 2px;
  text-transform: uppercase;
  display: flex;
}
.indexListInput {
  cursor: pointer;
  padding: 5px;
}
.actionIcnEdit {
  cursor: pointer;
  border: 1px solid #406cb9;
  //   border-radius: 7px;
  padding: 5px 7px;
  margin: 0 5px;
}
.actionIcnDel {
  cursor: pointer;
  border: 1px solid red;
  //   border-radius: 7px;
  padding: 5px 7px;
}
.addBtn {
  color: white;
  background-color: #406cb9;
  border: none;
  border-radius: 1px;
  margin-left: auto;
}
.indexListLabel {
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0;
  padding: 5px;
  width: 100%;
  height: 32px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', sans-serif;
  text-transform: none;
}

.linkBtnBuow,
.linkBtnBuowActive {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #e8ebed;
  width: 100%;
  max-width: fit-content;
  border: 1px solid #6a8cb1;
  color: #6a8cb1;
  border-radius: 25px;
  padding: 3px 6px;
}
.linkBtnBuowActive {
  background-color: #4774a5;
  color: white;
}
.defaultBuowHighlight {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #e1f0ff;
  width: 100%;
  max-width: fit-content;
  border: 2px solid #4a90e2;
  color: #6a8cb1;
  border-radius: 25px;
  padding: 3px 6px;
}
.arrowContainer,
.arrowContainerActive {
  border: 1px solid #6a8cb1;
  border-radius: 50%;
  margin-left: 5px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrowContainerActive {
  background-color: white;
  transform: rotate(180deg);
}
.indexListInputBuow {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: none !important;
}
.addBuowContainer {
  display: flex;
}
.buowPills {
  margin: 4px;
}
.tabNavSysConf {
  border-bottom: 1px solid #d1d4d7;
  width: 100% !important;
}
.buowNavPill {
  padding: 10px;
}
.buowNavPillActive {
  padding: 10px;
  font-weight: 800;
  border-bottom: 1px solid #1f5492;
  color: #1f5492;
}
.navModuleContainer {
  height: fit-content;
  max-height: 40vh !important;
  overflow-y: auto;
}
.overlay {
  position: fixed; /* Positioning and size */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.5); /* color */
  display: block; /* making it hidden by default */
  z-index: 9999;
}
.activePill {
  background-color: #4774a5 !important;
  color: white;
}

//Header
.modalHeader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .modalHeaderTitle {
    margin: 0;
    font-size: 24px;
    font-weight: 550;
    color: #484440;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.44px;
    font-family: sans-serif;
  }

  .modalHeaderIcon {
    cursor: pointer;
  }
}

.makeItDefaultModal {
  .modalHeader {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .modalHeaderTitle {
      margin: 0;
      font-size: 24px;
      font-weight: 550;
      color: #484440;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.44px;
      font-family: sans-serif;
    }

    .modalHeaderIcon {
      cursor: pointer;
    }
  }

  .makeItDefaultModalBody {
    padding: 1.5rem;

    .modalBodySection {
      font-family: Poppins;
      font-size: 22px;
      font-weight: 500;
      line-height: 33px;
      letter-spacing: 0.02em;
      text-align: left;
    }
    .confirmationText {
      text-align: center;
    }

    .makeItDefaultContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

//Footer
.makeItDefaultFooter {
  padding: 0px !important;

  .footerInternalDiv {
    width: 100%;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .yesBtn {
    background: #28a745 !important;
    color: white !important;
    border-color: transparent !important;
  }

  .noBtn {
    background: white;
    color: #1f5492;
    border: 1px solid #1f5492;
  }
}

.textInputBuow {
  margin: 0 5px;
}
.mandatoryAsterisk {
  padding-left: 4px;
}
.externalServicesToggle {
  width: 75% !important;
}
.externalServiceBorderContainer {
  width: 100%;
  margin-bottom: 1.5rem;
  padding: 1.5rem 0;
}
.externalServiceBorder {
  border-bottom: 1px solid #d1d4d7;
}
.externalServiceFormContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.textAreaHeader {
  width: 100% !important;
  min-height: 6vh !important;
  border-color: #d1d4d7;
  border-radius: 5px;
}
.externalApiRow {
  display: flex;
  width: 100%;
  margin: 5px 0;
}
.clipboardToggle{
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d1d4d7;
  border-radius: 8px;
}