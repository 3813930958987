.h3-div {
  padding-left: 36px;
  padding-top: 20px;
  padding-bottom: 10px;
  color: #494440;
  font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 2rem;
  font-weight: 700;
}
.timeDurationField{
  height: 6.4rem;
}
.validationMessage{
  color: red;
  font-size: 13px;
  max-width: 170px;
  min-width: 170px;
}

.newSelect .css-0 {
  display: contents !important;
}