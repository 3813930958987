.routingStepsPage {
    height: 89vh;
    display: flex;
    flex-direction: column;
    width: auto;
    overflow-y: auto;
    padding-right: 20px;
}

.routingStepsContent {
    margin-top: 2rem;
    margin-left: 1.8rem;

    .pageTitle {
        margin-top: 0px;
    }

    .searchSection {
        color: #494440;
        font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
        font-weight: 700;
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 0.5em;
        margin-top: 1.5em;
    }

    .keywordSearchTitle {
        color: #2e2c2a;
        font-size: 16.603px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.253px;
        padding-bottom: 4px;
    }

    .searchOuterContainer {
        display: flex;
        justify-content: space-between;
    }

    .createRecordBtnStyle {
        background: white;
        color: #1F5492;
        border: 1px solid #1F5492;
        font-weight: 600 !important;
    }
}

.routingStepsTable {
    max-width: calc(100vw - 12rem);

    td {
        text-align: center !important;
    }
}

.errorStyle {
    font-size: 1.3rem;
    color: red;
}

.routingStepsModal {
    .modalHeader {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .modalHeaderTitle {
            margin: 0;
            font-size: 24px;
            font-weight: 550;
            color: #484440;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.44px;
            font-family: sans-serif;
        }

        .modalHeaderIcon {
            cursor: pointer;
        }
    }

    .routingStepsModalBody {
        padding: 2.5rem 1rem 0.5rem;

        .modalBodySection {
            display: flex;
            flex-wrap: wrap;
        }

        .inputFieldSection {
            height: 80px;

            label {
                display: flex;
            }

            input {
                display: block;
                // width: 222px;
                width: 100%;
                height: 32px;
                border: 1px solid #ced4da;
                border-radius: 4px;
            }

            select {
                display: block;
                width: 222px;
                height: 32px;
                background-color: #fff;
                font-weight: 400;
                border: 1px solid #ced4da;
                border-radius: 4px;
            }

            .radioBtnContainer {
                display: flex;
                align-items: center;
            }

            .radioInputOuterDiv {
                display: flex;
                margin-top: 3rem;
                align-items: center;

                input {
                    width: 20px;
                    height: 20px;
                    margin: 0px 8px;
                    border-radius: 10px;
                    display: inline-grid;
                }
            }

            .radioInputOuterDiv input[type='radio'] {
                appearance: none;
                -webkit-appearance: none;
                width: 18px;
                height: 18px;
                margin: 4px 8px;
                border-radius: 10px;
                display: inline-grid;
                border: 3px solid #ced4da;
                background-color: white;
            }

            .radioInputOuterDiv input[type='radio']:checked {
                background-color: #1f5492;
            }
        }

        .searchFieldDiv {
            flex: 1 0 20%;
            height: 80px;
        }
    }

    .routingStepsFooter {
        .cancelRecordBtn {
            background: white;
            color: #1F5492;
            border: 1px solid #1F5492;
        }

        .saveRecordBtn {
            background: #28A745 !important;
            color: white !important;
            border-color: transparent !important;
        }
    }

    .createRecordBtnStyle {
        background: white;
        color: #1F5492;
        border: 1px solid #1F5492;
        font-weight: 600 !important;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}