.changePage {
  background-color: #ebebeb;
  border: 2px solid #6d6c6c;
  border-radius: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageNumberField {
  width: 4rem;
  padding: 3px;
  margin: 3px;
  text-align: center;
}
.pagesLabel {
  font-size: 1.5em;
}
